<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
				<el-select v-model="trader" placeholder="请选择商户" size="mini" class="mr20" clearable>
					<el-option v-for="item in traderListData" :key="item.business_no" :label="item.business_name" :value="item.business_no"></el-option>
				</el-select>
				<el-select v-model="payType" placeholder="请选择支付方式" size="mini" class="mr20" clearable>
					<el-option key="" label="全部" value=""></el-option>
					<el-option v-for="item in payTypeArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"  @click="getData" :disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<!-- 交易报表这里表头，   业务类型   交易金额    代理手续费   代理到账     商家手续费    商家到账      暂时先改成这样 -->
			<el-table style=" width:1200px" size="mini" :data="tableData" border="" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column prop="business_no" label="商户号" align="center">
					<template slot-scope="scope">
						<span v-if="scope.$index<tableData.length-2">{{scope.row.business_no}}</span>
						<span v-else="">{{scope.row.agent_username}}</span>
						
					</template>
				</el-table-column>
				<el-table-column prop="business_name" label="商户名称" align="center">
					<template slot-scope="scope">
						<span v-if="scope.$index<tableData.length-2">{{scope.row.business_name}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="pay_type_name" label="业务类型" align="center">
					<template slot-scope="scope">
						{{scope.row.pay_type_name}}
					</template>
				</el-table-column>
				<el-table-column label="交易金额" align="center">
					<template slot-scope="scope">
						<!-- {{scope.row.add_money |  tofixed}} -->
						<div v-if="scope.$index == tableData.length-2">
							<el-popover
								placement="top"
								trigger="hover">
								<div>
									<p v-for="(val, key) in scope.row.success_money" :key="key">
										<template v-if="key !='money_to_usdt'">
											{{val}}<sub>{{key}}</sub>
										</template>
									</p>
								</div>
								<p slot="reference">{{scope.row.success_money.money_to_usdt || 0}} <sub>usdt</sub></p>
							</el-popover>
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
							<el-popover
								placement="top"
								trigger="hover">
								<div>
									<p v-for="(val, key) in scope.row.success_money" :key="key">
										<template v-if="key !='money_to_usdt'">
											{{val}}<sub>{{key}}</sub>
										</template>
									</p>
								</div>
								<p slot="reference">{{scope.row.success_money.money_to_usdt|| 0}} <sub>usdt</sub></p>
							</el-popover>
						</div>
						<!-- <p v-else>{{scope.row.success_money.money_to_usdt}} <sub>usdt</sub></p> -->
						<p v-else>
							<el-popover
								placement="top"
								trigger="hover">
								<div>
									<p v-for="(val, key) in scope.row.success_money" :key="key">
										<template v-if="key !='money_to_usdt'">
											{{val}}<sub>{{key}}</sub>
										</template>
									</p>
								</div>
								<p slot="reference">{{scope.row.success_money.money_to_usdt|| 0}} <sub>usdt</sub></p>
							</el-popover>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="代理手续费 ‰" align="center">
					<template slot-scope="scope">
						<!-- {{scope.row.agent_fee | tofixed}} -->
						<div v-if="scope.$index == tableData.length-2">
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
						</div>
						<p v-else>{{scope.row.agent_fee}}</p>
					</template>
				</el-table-column>
				<el-table-column label="代理到账" align="center">
					<template slot-scope="scope">
						<!-- {{scope.row.fee_gold_ag | tofixed}} -->
						<div>
							<el-popover
								placement="top"
								trigger="hover">
								<div>
									<p v-for="(val, key) in scope.row.fee_gold_ag" :key="key">
										<template v-if="key !='money_to_usdt'">
											{{val}}<sub>{{key}}</sub>
										</template>
									</p>
								</div>
								<p slot="reference">{{scope.row.fee_gold_ag.money_to_usdt || 0}} <sub>usdt</sub></p>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商家手续费 ‰" align="center">
					<template slot-scope="scope">
						<!-- {{scope.row.trader_fee | tofixed}} -->
						<div v-if="scope.$index == tableData.length-2">
						</div>
						<div v-else-if="scope.$index == tableData.length-1">
						</div>
						<p v-else>{{scope.row.trader_fee}}</p>
					</template>
				</el-table-column>
				<el-table-column label="商家到账" align="center">
					<template slot-scope="scope">
						<div>
							<el-popover
								placement="top"
								trigger="hover">
								<div>
									<p v-for="(val, key) in scope.row.trader_gold" :key="key">
										<template v-if="key !='money_to_usdt'">
											{{val}}<sub>{{key}}</sub>
										</template>
									</p>
								</div>
								<p slot="reference">{{scope.row.trader_gold.money_to_usdt || 0}} <sub>usdt</sub></p>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="当前余额" align="center">
					<template slot-scope="scope">
						{{scope.row.trader_money | tofixed}}
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="利润" align="center">
					<template slot-scope="scope">
						{{scope.row.fee_gold_ag | tofixed}}
					</template>
				</el-table-column> -->
				
			
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>


	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],	
				date: null,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				//商家列表
				traderListData:[],
				trader:'',
				//支付类型
				payType: '',
				payTypeArr: [],
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.traderList()			
			this.payTypeList()
			this.orderReport()

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderReport();
			},
			payTypeList() {
				this.$api.payType({hasRepay: 2}).then(res => {
					if (res.status === 1) {
						this.payTypeArr = res.data.data
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			traderList() {
				this.$api.traderList({}).then(res => {
					if (res.status === 1) {
						this.traderListData = res.data
						
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderReport()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderReport()
			},
			

			orderReport() {
				let data = {
					order_id: this.order_id,
					pay_type: this.payType,
					state: this.state,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					page: this.page,
					size: this.pageSize,
					business_no: this.trader,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.orderReport(data).then(res => {
					this.disLoading = false
					this.loading = false;
					if (res.status === 1) {
						this.tableData = res.data.data.filter(item=>{
							// if(!item.agent_username){
								return item
							// }
						});
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			
		}
	};
</script>

<style>
</style>
